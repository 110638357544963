<template>
  <article
    class="version-list-item onyx--text"
    :style="$vuetify.breakpoint.xsOnly && 'padding-left: 0; padding-bottom: 10px;'"
  >
    <header class="header">
      <div class="item-headline">
        <span
          v-if="$vuetify.breakpoint.smAndUp"
          class="dot"
        />
        <div>
          <router-link
            :to="{ path: `/projects/${project._id}/edit`, query: { version: version._id }}"
            style="text-decoration: none;"
          >
            <h1 class="title-text">
              {{ version.title }}
            </h1>
            <h1 class="title-text">
              {{ versionText }}
            </h1>
          </router-link>
          <v-tooltip right>
            <template #activator="{ on }">
              <span
                class="type text-capitalize shamrock--text"
                v-on="type === 'baseline design' ? on : null"
              >
                {{ type }}
              </span>
            </template>
            {{ type === 'baseline design' ? 'Contains data for the current state of your project' : 'Add data for planned improvements to your project' }}
          </v-tooltip>
        </div>
        <ItemOptionsMenu
          tooltip="Options"
          :options="[
            { icon: 'mdi-delete', color: 'red', text: 'Delete version', value: 'delete' },
            { icon: 'mdi-content-duplicate', color: 'metal', text: 'Duplicate version', value: 'duplicate' },
            { icon: 'mdi-repeat', color: 'metal', text: `Make this a ${type === 'design alternative' ? 'baseline design' : 'design alternative'}`, value: 'toggle' }
          ]"
          @option-clicked="handleOptionClick"
        />
      </div>
      <div class="metadata">
        <span class="date">
          Last modified <strong>{{ getDateMonthAndYear(new Date(version.lastModifiedDate)) }}</strong>
        </span>
        <v-select
          v-if="user.isAdmin"
          v-model="newCPDVersions"
          multiple
          placeholder=""
          :items="[2, 3]"
          class="pt-1 pb-0"
          label="Version Compatability"
          @change="handleCPDVersionChange"
        />
      </div>
    </header>
    <main class="main">
      <div class="description">
        <p
          class="ma-0"
          :style="editingDescription && 'width: 100%;'"
        >
          <span v-if="!editingDescription">{{ version.description || 'No description' }}</span>
          <v-textarea
            v-else
            v-model="newDescription"
            v-on-clickaway="toggleEditingDescription"
            rows="1"
            placeholder="Enter a description"
            dense
          />
        </p>
        <v-tooltip right>
          <template #activator="{ on }">
            <v-btn
              icon
              small
              class="ml-1"
              @click="toggleEditingDescription"
              v-on="on"
            >
              <v-icon
                small
                dense
                :color="editingDescription ? 'shamrock' : 'inherit'"
              >
                {{ editingDescription ? 'mdi-check' : 'mdi-pencil' }}
              </v-icon>
            </v-btn>
          </template>
          {{ editingDescription ? 'Save changes' : 'Edit description' }}
        </v-tooltip>
      </div>
    </main>
    <footer class="actions">
      <div class="buttons">
        <router-link
          :to="{ path: `/projects/${project._id}/edit`, query: { version: version._id }}"
          style="text-decoration: none;"
        >
          <v-btn
            class="detail button d-flex align-center mr-3"
            outlined
            tile
          >
            <span style="font-size: 1.3em; margin-right: 5px; margin-top: 1px;">+</span>
            Add detail
          </v-btn>
        </router-link>
        <v-btn
          v-if="type === 'baseline design'"
          class="detail button d-flex align-center mr-3"
          :outlined="!version.isActiveBaseline"
          tile
          color="shamrock white--text"
          elevation="0"
          :style="{ 'pointer-events': version.isActiveBaseline ? 'none' : 'all' }"
          @click="$emit('make-primary-baseline')"
        >
          <v-icon
            left
            small
          >
            {{ version.isActiveBaseline ? 'mdi-lightbulb-on' : 'mdi-lightbulb-outline' }}
          </v-icon>
          {{ version.isActiveBaseline ? `${$vuetify.breakpoint.smAndUp ? 'Primary baseline' : 'Primary'}` : `${$vuetify.breakpoint.smAndUp ? 'Make primary baseline' : 'Primary'}` }}
        </v-btn>
        <v-btn
          v-if="type === 'design alternative'"
          class="detail button d-flex align-center mr-3"
          :outlined="!version.isActive"
          tile
          color="shamrock white--text"
          elevation="0"
          :style="{ 'pointer-events': version.isActive ? 'none' : 'all' }"
          @click="$emit('make-primary')"
        >
          <v-icon
            left
            small
          >
            {{ version.isActive ? 'mdi-lightbulb-on' : 'mdi-lightbulb-outline' }}
          </v-icon>
          {{ version.isActive ? `${$vuetify.breakpoint.smAndUp ? 'Primary design' : 'Primary'}` : `${$vuetify.breakpoint.smAndUp ? 'Make primary design' : 'Primary'}` }}
        </v-btn>
        <ScorecardDialog
          :version-id="version._id"
          tiled-button
        />
      </div>
    </footer>
  </article>
</template>

<script>
import ItemOptionsMenu from '@/components/atoms/ItemOptionsMenu'
import getDateMonthAndYear from '@/helpers/getDateMonthAndYear'
import ScorecardDialog from '@/components/modals/ScorecardDialog'
import { mixin as clickaway } from 'vue-clickaway2'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')

export default {
  name: 'VersionListItem',
  components: {
    ItemOptionsMenu,
    ScorecardDialog
  },
  mixins: [clickaway],
  props: {
    project: {
      type: Object,
      required: true
    },
    version: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      getDateMonthAndYear,
      editingDescription: false,
      newCPDVersions: []
    }
  },
  computed: {
    ...mapAuthGetters(['user']),
    versionText () {
      const vString = this.newCPDVersions?.map((v) => `${v}.0`).join('/') || '2.0'
      return `(${vString})`
    }
  },
  created () {
    this.newDescription = this.version.description
    this.newCPDVersions = this.version.cpdVersions
  },
  methods: {
    canShowCPDVersion (version) {
      const canShowVersion = ((!this.version.cpdVersions || this.version.cpdVersions.length === 0) && version === 2) ||
        (this.version.cpdVersions && this.version.cpdVersions.includes(version))
      return canShowVersion
    },
    handleCPDVersionChange (value) {
      this.$emit('update-cpd-versions', this.newCPDVersions)
    },
    handleOptionClick (option) {
      this.$emit(option.value)
    },
    toggleEditingDescription () {
      if (this.editingDescription) {
        this.$emit('update-description', this.newDescription)
      }
      this.editingDescription = !this.editingDescription
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-select {
    display: inline-block;
    width: auto;
    min-width: 11em;
  }
  .version-list-item {
    padding-left: 24px;
    margin: 30px 0 10px 0;
    text-align: left;
    * {
      color: inherit;
    }
    .header {
      .item-headline {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .dot {
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          margin-right: 13px;
          background: #000;
          border-radius: 50%;
          width: 7px;
          height: 7px;
        }
        div {
          .title-text {
            display: inline;
            font-weight: 400;
            font-size: 1.3em;
          }
          .version-text {
            display: inline;
            font-weight: 400;
            font-size: 1.3em;
          }
          .type {
            display: inline;
            margin-left: 6px;
            font-size: 0.9em;
            font-weight: 300;
          }
        }
      }
      .metadata {
        margin-top: 5px;
        font-size: 0.8em;
        color: #888;
        .date {
          margin-right: 20px;
          strong {
            font-weight: normal;
            color: #000;
          }
        }
      }
    }
    .main {
      margin: 15px 0 20px 0;
      .description {
        display: flex;
        align-items: center;
        max-width: 450px;
        font-size: 0.8em;
        color: #a8a8a8;
        font-weight: 200;
        letter-spacing: 0.4px;
      }
    }
    .actions {
      .buttons {
        display: flex;
        flex-wrap: wrap;
        .button {
          font-weight: 300 !important;
          margin-bottom: 10px;
        }
      }
    }
  }
</style>
