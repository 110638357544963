const areaUnits = [
  { text: 'sq feet', multiplier: 10.764, systemOfMeasurement: 'imperial' },
  { text: 'm2', multiplier: 1, systemOfMeasurement: 'metric' },
  { text: 'acres', multiplier: 0.000247105, systemOfMeasurement: 'imperial' },
  { text: 'hectares', multiplier: 0.0001, systemOfMeasurement: 'metric' }
]

export default function (area, systemOfMeasurement, round = true) {
  const systemUnits = areaUnits.filter((x) => x.systemOfMeasurement === systemOfMeasurement)
  let ret
  let i = 2
  do {
    i--
    ret = area * systemUnits[i].multiplier
  } while (ret < 1)
  if (round) {
    const decFactor = Math.pow(10, i)
    ret = (Math.round(ret * decFactor) / decFactor).toLocaleString()
  }
  return { value: ret, units: systemUnits[i].text }
}
