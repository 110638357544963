<template>
  <v-dialog
    v-model="isVisible"
    width="600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    scrollable
  >
    <ValidationObserver v-slot="{ handleSubmit, invalid, errors }">
      <v-form
        ref="form"
        class="d-flex flex-column"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <v-card height="100%">
          <div
            style="position: relative;"
            @click="deleteMenuVisible = false"
          >
            <v-overlay
              :value="deleteMenuVisible"
              absolute
            />
            <v-card-title>Project settings</v-card-title>
            <v-card-text>
              <ValidationProvider
                v-slot="{ errors }"
                name="Title"
                rules="required|max:70"
              >
                <v-text-field
                  v-model="updatedProject.title"
                  label="Title"
                  :counter="70"
                  :error-messages="errors"
                  data-vv-name="title"
                />
              </ValidationProvider>
              <v-textarea
                v-model="updatedProject.description"
                placeholder="Add a project description"
                label="Description"
                rows="3"
                hide-details
              />
              <v-select
                v-if="user.isAdmin"
                v-model="updatedProject.cpdVersions"
                multiple
                placeholder=""
                :items="[2, 3]"
                class="mt-4 pb-0"
                label="Version Compatability"
              />
              <div class="d-flex align-center flex-wrap mt-7 pb-2">
                <div
                  class="mt-1 mr-5"
                  style="opacity: 0.7;"
                >
                  Is your project pursuing LEED or SITES certification?
                </div>
                <v-checkbox
                  v-model="updatedProject.certifications"
                  label="LEED"
                  value="LEED"
                  flat
                  class="mt-0 mr-5"
                  hide-details
                />
                <v-checkbox
                  v-model="updatedProject.certifications"
                  label="SITES"
                  value="SITES"
                  flat
                  class="mt-0 mr-5"
                  hide-details
                />
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Is Study"
                rules="required"
              >
                <v-switch
                  v-model="updatedProject.isStudy"
                  :error-messages="errors"
                  data-vv-name="isStudy"
                  label="Is this an academic study or project not expected to be built?"
                  hint="Hint: To edit other metadata, use the buttons below the project's image."
                  class="mt-4"
                  color="shamrock"
                  persistent-hint
                />
              </ValidationProvider>
              <v-alert
                v-if="errors.length"
                dense
                outlined
                type="error"
                class="mt-4 mb-0 body-2"
                color="error"
              >
                <div
                  v-for="(error, index) in errors"
                  :key="index"
                >
                  {{ error }}
                </div>
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mx-2 mb-3"
                label="Delete project"
                color="error white--text"
                elevation="0"
                @click.stop="deleteMenuVisible = true"
              >
                Delete project
              </v-btn>
              <v-spacer />
              <v-btn
                class="mx-2 mb-3"
                type="button"
                elevation="0"
                @click="() => onReset()"
              >
                Discard
              </v-btn>
              <v-btn
                class="mx-2 mb-3"
                color="shamrock white--text"
                type="submit"
                :loading="loading"
                elevation="0"
                :disabled="invalid"
              >
                Save
              </v-btn>
            </v-card-actions>
          </div>
          <v-sheet
            v-if="deleteMenuVisible"
            class="body-2 pt-4 pb-2 px-5"
          >
            <div class="mb-3">
              Are you sure you want to delete this project? This action is irreversible.
            </div>
            <div
              class="d-flex"
              :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : 'flex-row'"
            >
              <v-btn
                color="metal white--text"
                class="d-block mb-3"
                :class="{ 'mr-3': $vuetify.breakpoint.smAndUp }"
                elevation="0"
                @click="deleteMenuVisible = false"
              >
                No, I'll keep it.
              </v-btn>
              <v-btn
                color="red"
                outlined
                :loading="deleting"
                @click="handleDelete"
              >
                I'm sure. Delete this project.
              </v-btn>
            </div>
          </v-sheet>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('projects')
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
// const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('projects')

export default {
  name: 'ProjectSettings',
  components: { ValidationObserver, ValidationProvider },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      updatedProject: null,
      loading: false,
      deleteMenuVisible: false,
      deleting: false
    }
  },
  computed: {
    ...mapGetters(['project']),
    ...mapAuthGetters(['user']),
    isVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('setVisible', val)
      }
    }
  },
  watch: {
    isVisible: {
      immediate: true,
      handler () {
        this.updatedProject = { ...this.project }
      }
    }
  },
  methods: {
    ...mapActions(['updateProject', 'deleteProject']),
    async onSubmit () {
      this.loading = true
      await this.updateProject(this.updatedProject)
      this.loading = false
      this.$emit('setVisible', false)
    },
    onReset () {
      this.$emit('setVisible', false)
    },
    async handleDelete () {
      this.deleting = true
      await this.deleteProject()
      this.deleting = false
    }
  }
}
</script>
