import ordinalize from '@/helpers/ordinalize'

export default function parseDate (date) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const dateOfMonth = ordinalize(new Date(date).getDate())
  const month = months[new Date(date).getMonth()]

  return {
    date: dateOfMonth,
    month,
    year: date.getFullYear()
  }
}
