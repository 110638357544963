<template>
  <div>
    <div class="title pt-4 smaller">
      Project Information
    </div>
    <v-divider class="mt-2 mb-2" />
    <ExistingSiteConditions
      :visible="showExistingSiteConditions"
      @setVisible="val => showExistingSiteConditions = val"
    />
    <div
      class="mt-5 mb-3 d-flex justify-start flex-wrap"
      :style="{ 'flex-direction': $vuetify.breakpoint.smAndUp ? 'row' : 'column' }"
    >
      <!-- PROJECT TYPE -->
      <ProjectMetaItem
        tooltip="Change project type"
        prompt="What best describes your project?"
        :items="projectTypes.map((x) => ({ text: $t('project.types.' + x.name), value: x.name }) )"
        :value.sync="type"
      />
      <!-- COMPLETION DATE -->
      <v-menu
        ref="datemenu"
        v-model="showDateMenu"
        offset-y
        bottom
        :close-on-content-click="false"
        :return-value.sync="completionDate"
        nudge-bottom="6"
      >
        <template #activator="menu">
          <v-tooltip top>
            <template #activator="tooltip">
              <v-chip
                color="leaf primary--text"
                class="font-weight-medium mx-2 mb-2"
                v-on="{ ...tooltip.on, ...menu.on }"
              >
                {{ completionDateString }}
              </v-chip>
            </template>
            Edit completion date
          </v-tooltip>
        </template>
        <CompletionDatePicker
          :initial-date="new Date(project.completionDate).toISOString().substr(0, 7)"
          @save="e => $refs.datemenu.save(e)"
          @close="showDateMenu = false"
        />
      </v-menu>
      <!-- SYSTEM OF MEASUREMENT -->
      <ProjectMetaItem
        tooltip="Change system of measurement"
        prompt="Choose your units:"
        :items="['metric', 'imperial']"
        :value="project.systemOfMeasurement"
        @update:value="updateProjectSystemOfMeasurement"
      >
        <template #suffix>
          units
        </template>
      </ProjectMetaItem>
      <!-- METRIC SMALL UNITS -->
      <ProjectMetaItem
        v-if="project.systemOfMeasurement === 'metric'"
        tooltip="Small units"
        prompt="Choose the units used for small lengths:"
        :items="[
          { text: 'millimetres', value: 'mm' },
          { text: 'centimetres', value: 'cm' }
        ]"
        :value.sync="smallUnits"
      />
      <!-- AREA -->
      <v-menu
        v-model="showAreaMenu"
        offset-y
        bottom
        :close-on-content-click="false"
        :return-value.sync="completionDate"
        nudge-bottom="6"
      >
        <template #activator="menu">
          <v-tooltip top>
            <template #activator="tooltip">
              <v-chip
                color="leaf primary--text"
                class="font-weight-medium mx-2 mb-3"
                v-on="{ ...menu.on, ...tooltip.on }"
                @click="redrawProjectArea()"
              >
                {{ parsedArea }}
              </v-chip>
            </template>
            Update area
          </v-tooltip>
        </template>
      </v-menu>
      <!-- CURRENT PHASE -->
      <ProjectMetaItem
        tooltip="Update project phase"
        prompt="Select a phase:"
        :items="['Concept', 'Schematic', 'Design Development', 'Construction Drawings', 'Construction']"
        :value.sync="currentPhase"
      /><!-- manual area input  -->
      <ManualAreaInputDialog
        :show.sync="manualAreaInputDialogVisible"
        :title="`Current area (${project.location.area && calculatedArea === project.location.area ? 'calculated from boundary' : 'entered manually'})`"
        :calculated-area="project.location.area"
        :area-in-m2.sync="newProjectArea"
        :system-of-measurement="project && project.systemOfMeasurement"
        prominent-redraw
        @back="() => {
          newProjectArea = project.location.area
          manualAreaInputDialogVisible = false
        }"
        @next="saveAreaChanges"
        @redraw="$router.push(`/projects/${project._id}/location`)"
      />
    </div>
    <div>
      <div class="title smaller">
        Site Geolocation Data
      </div>
      <v-divider class="mt-2 mb-2" />
      <v-btn
        class="detail button align-center mt-4"
        :outlined="true"
        tile
        color="shamrock white--text"
        elevation="0"
        @click="showExistingSiteConditions = true"
      >
        <v-icon
          left
          small
        >
          mdi-plus
        </v-icon>
        Add Biodiversity Site Conditions
      </v-btn>
      <div
        class="mt-5 mb-3 d-flex justify-start flex-wrap"
        :style="{ 'flex-direction': $vuetify.breakpoint.smAndUp ? 'row' : 'column' }"
      >
        <EnrichedProjectConditions />
      </div>
    </div>
  </div>
</template>

<script>
import turfArea from '@turf/area'
import parseDate from '@/helpers/parseDate'
import CompletionDatePicker from '@/components/atoms/CompletionDatePicker'
import ManualAreaInputDialog from '@/components/modals/ManualAreaInputDialog'
import unitTools from '@/mixins/unitTools'
import ProjectMetaItem from './ProjectMetaItem'

import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'
import EnrichedProjectConditions from './EnrichedProjectConditions'
import ExistingSiteConditions from '@/views/project/existingSiteConditions/ExistingSiteConditions.vue'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('projects')

export default {
  name: 'ProjectMeta',
  components: {
    ExistingSiteConditions,
    CompletionDatePicker,
    ProjectMetaItem,
    ManualAreaInputDialog,
    EnrichedProjectConditions
  },
  mixins: [unitTools],
  data () {
    return {
      showDateMenu: false,
      showAreaMenu: false,
      manualAreaInputDialogVisible: false,
      calculatedArea: 0,
      newProjectArea: 0,
      showExistingSiteConditions: false
    }
  },
  computed: {
    ...mapGetters(['project']),
    ...mapGlobalGetters(['projectTypes']),
    parsedArea () {
      if (!this.project) return ''
      const val = this.project.location.area
      return this.getUnitVal(val, 'm2').str
    },
    completionDateString () {
      const d = this.project && this.project.completionDate ? new Date(this.project.completionDate) : new Date()
      const { month, year } = parseDate(d)
      return `${month} ${year}`
    },
    type: {
      get () {
        return this.project.type
      },
      set (type) {
        this.updateProject({ type: type.value || type })
      }
    },
    completionDate: {
      get () {
        return this.project.completionDate
      },
      set (e) {
        this.updateProject({ completionDate: e })
      }
    },
    smallUnits: {
      get () {
        const lookup = {
          mm: 'millimetres',
          cm: 'centimetres'
        }
        return lookup[this.project.smallUnits]
      },
      set (e) {
        this.updateProject({ smallUnits: e.value })
      }
    },
    currentPhase: {
      get () {
        return this.project.currentPhase
      },
      set (e) {
        this.updateProject({ currentPhase: e })
      }
    },
    certifications: {
      get () {
        return this.project.certifications
      },
      set (e) {
        this.updateProject({ certifications: e })
      }
    }
  },
  methods: {
    ...mapMutations([]),
    ...mapActions(['updateProject', 'updateProjectSystemOfMeasurement']),
    redrawProjectArea () {
      this.manualAreaInputDialogVisible = true
      this.newProjectArea = this.project.location.area
      if (this.project.location.drawData) {
        this.calculatedArea = turfArea(this.project.location.drawData)
      }
    },
    saveAreaChanges () {
      this.updateProject({ location: { ...this.project.location, area: this.newProjectArea } })
      this.manualAreaInputDialogVisible = false
    }
  }
}
</script>

<style scoped>
.smaller {
  font-size: 1rem !important;
}
</style>
