<template>
  <div class="list px-3">
    <v-list v-if="loading">
      <v-skeleton-loader
        v-for="n in 3"
        :key="n"
        type="list-item-three-line"
        class="my-3"
      />
    </v-list>
    <div v-else>
      <VersionListItem
        v-for="version in versions"
        :key="version._id"
        :project="project"
        :version="version"
        :type="version.isDesignProposal ? 'design alternative' : 'baseline design'"
        @delete="requestDeleteVersion(version._id)"
        @toggle="toggleDesignProposal(version)"
        @duplicate="requestDuplicate(version)"
        @make-primary="$emit('on-make-primary', version._id)"
        @make-primary-baseline="$emit('on-make-primary-baseline', version._id)"
        @update-description="description => updateDescription(version._id, description)"
        @update-cpd-versions="cpdVersions => updateCPDVersions(version._id, cpdVersions)"
      />
    </div>
    <v-dialog
      v-model="showDuplicateVersionForm"
      width="400"
    >
      <v-card>
        <v-card-title>
          Duplicate version
        </v-card-title>
        <v-card-text v-if="duplicatingVersion">
          <p>
            Creating a duplicate of
            <strong>{{ duplicatingVersion.title }}</strong>
          </p>
          <v-form @submit.prevent="confirmDuplicate">
            <v-text-field
              v-model="duplicateTitle"
              label="Choose a title for the duplicate"
            />
            <div class="d-flex justify-end">
              <v-btn
                color="error"
                outlined
                class="mr-3"
                elevation="0"
                @click="cancelDuplicate"
              >
                Cancel
              </v-btn>
              <v-btn
                type="submit"
                color="shamrock white--text"
                elevation="0"
                :loading="duplicatingLoading"
              >
                Confirm
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      :show="showConfirmDeleteDialog"
      cancel-color="primary"
      confirm-color="red"
      confirm-text="delete"
      question="Are you sure you want to delete this version?"
      @confirm="handleDeleteVersion"
      @cancel="showConfirmDeleteDialog = false"
    />
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway2'
import ConfirmDialog from '@/components/atoms/ConfirmDialog'
import VersionListItem from './VersionListItem'

import getDateAndMonth from '@/helpers/getDateAndMonth'

import { createNamespacedHelpers, mapActions as mapGlobalActions } from 'vuex'
const { mapGetters: mapVersionGetters, mapMutations: mapVersionMutations, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')
const { mapActions: mapProjectActions } = createNamespacedHelpers('projects')

export default {
  name: 'VersionList',
  components: {
    ConfirmDialog,
    VersionListItem
  },
  mixins: [clickaway],
  props: {
    projectId: {
      type: String,
      required: true
    },
    maxHeight: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      inFocus: null,
      getDateAndMonth,
      showConfirmDeleteDialog: false,
      deletingId: null,
      loading: true,
      duplicatingVersion: null,
      duplicateTitle: '',
      showDuplicateVersionForm: false,
      duplicatingLoading: false
    }
  },
  computed: {
    ...mapVersionGetters(['versions']),
    project () {
      return this.$store.state.projects.project
    }
  },
  created () {
    this.fetchVersions({ id: this.projectId }).then(() => {
      if (this.versions.length === 0) {
        this.$emit('show-form')
      }
      this.loading = false
    })
  },
  methods: {
    ...mapGlobalActions(['showSnackbar']),
    ...mapVersionMutations([]),
    ...mapVersionActions(['fetchVersions', 'fetchVersion', 'deleteVersion', 'createVersion', 'duplicateVersion']),
    ...mapProjectActions(['refreshProject']),
    async toggleDesignProposal (item) {
      if (item.isDesignProposal && this.project.activeVersion === item._id) {
        return this.showSnackbar({ color: 'shamrock', text: 'Primary versions must be design alternatives. To make this version an baseline design, please first change your primary version.' })
      }
      if (!item.isDesignProposal && this.project.activeBaselineVersion === item._id) {
        return this.showSnackbar({ color: 'shamrock', text: 'Primary baseline versions must be an baseline design. To make this version an  design alternatives, please first change your primary baseline version.' })
      }
      await this.$axios.put(`/versions/${item._id}/design_proposal`)
      this.refreshProject()
      this.fetchVersions({ id: this.projectId })
    },
    requestDeleteVersion (id) {
      if (this.project.activeVersion === id) {
        return this.showSnackbar({ color: 'shamrock', text: 'Cannot delete primary version. Please make another version primary to delete this one.' })
      }
      this.deletingId = id
      this.showConfirmDeleteDialog = true
    },
    handleDeleteVersion () {
      this.showConfirmDeleteDialog = false
      this.deleteVersion(this.deletingId)
    },
    async requestDuplicate (version) {
      this.duplicatingVersion = version
      const str = version.title
      let end = str.substr(str.length - 3)
      if (/\(\d\)/.test(end)) {
        const n = +end.substr(1, 1)
        this.duplicateTitle = str.replace(end, `(${n + 1})`)
      } else {
        this.duplicateTitle = str + ' (1)'
      }
      this.showDuplicateVersionForm = true
    },
    async confirmDuplicate () {
      this.duplicatingLoading = true
      await this.duplicateVersion({
        versionToDuplicate: this.duplicatingVersion._id,
        title: this.duplicateTitle
      })
      this.duplicatingLoading = false
      this.showDuplicateVersionForm = false
    },
    cancelDuplicate () {
      this.duplicatingVersion = null
      this.duplicateTitle = ''
      this.showDuplicateVersionForm = false
    },
    async updateDescription (versionId, description) {
      await this.$axios.put(`/versions/${versionId}`, {
        description
      })
      this.fetchVersions({ id: this.projectId })
    },
    async updateCPDVersions (versionId, cpdVersions) {
      await this.$axios.put(`/versions/${versionId}`, {
        cpdVersions
      })
      this.fetchVersion({ id: versionId })
    }
  }
}
</script>
